import React from "react";
import NetomniaRegions from "../../sections/index/NetomniaRegions";
import PageWrapper from "../../components/PageWrapper";
import Seo from "./../../components/Seo";

const LandingPage = () => {
    return (
        <>
            <Seo page="areas"/>
            <PageWrapper>
                <NetomniaRegions mapOnly={false}/>
            </PageWrapper>
        </>
    );
};
export default LandingPage;
